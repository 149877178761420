import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import blob from "../../assets/images/blogbob.svg"
import SnappyLogo from "../../assets/images/snappylogosmall.png"
import PortfolioCTA from "../../components/PortfolioCTA"

import { FaPhone } from "react-icons/fa"
import { makeStyles, useTheme } from "@material-ui/core/styles"
// import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles"
import Avatar from "@material-ui/core/Avatar"
// import Breadcrumbs from "@material-ui/core/Breadcrumbs"
import Button from "@material-ui/core/Button"
import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
// import Chip from "@material-ui/core/Chip"
// import ClickAwayListener from "@material-ui/core/ClickAwayListener"
import Container from "@material-ui/core/Container"
import Divider from "@material-ui/core/Divider"
// import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import Grid from "@material-ui/core/Grid"
// import Grow from "@material-ui/core/Grow"
import Hidden from "@material-ui/core/Hidden"
// import { Link as MuiLink } from "@material-ui/core/Link"
import Paper from "@material-ui/core/Paper"
// import Popper from "@material-ui/core/Popper"
// import MenuItem from "@material-ui/core/MenuItem"
// import MenuList from "@material-ui/core/MenuList"
import Rating from "@material-ui/lab/Rating"
import Typography from "@material-ui/core/Typography"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import truncate from "lodash.truncate"
import DirectoryBreadcrumbs from "../../components/templates/work/DirectoryBreadcrumbs"

const primary = "#26a27b"
const dark = "#3a866e"

const Background = styled.div`
  background-image: url(${blob});
  background-color: #fff;
  background-size: auto;
  background-repeat: no-repeat;
  border-bottom: 2px solid ${primary};
  background-position: 0% 0%;
  color: white;
  padding: 4rem 0;
  text-align: center;
  @media only screen and (max-width: 1000px) {
    background-position: 50% 50%;
  }
`

const WebCompanies = [
  {
    id: 0,
    name: "3 Media Web",
    tagline: "Growing Your Business Is Our Business",
    img: "https://i.imgur.com/2aRa0YG.png",
    url: "https://www.3mediaweb.com/",
    location: "Grand Rapids, MI",
  },
  {
    id: 1,
    name: "Teal Media",
    tagline: "Creative with a Conscience",
    img: "https://i.imgur.com/c8T9mDP.png",
    url: "https://tealmedia.com/",
    location: "Royal Oak, MI",
  },
  {
    id: 2,
    name: "BMG Media Co.",
    tagline: "Michigan's Best Web Development Company",
    img: "https://i.imgur.com/nL6itl9.jpg",
    url: "https://bmgmediaco.com/",
    location: "Birmingham, MI",
  },
  {
    id: 3,
    name: "Symmetri Marketing",
    tagline: "We create meaningful brand interactions",
    img: "https://i.imgur.com/TREa6Ro.png",
    url: "https://symmetrimarketing.com/",
    location: "Birmingham, MI",
  },
  {
    id: 4,
    name: "Ignite Media",
    tagline: "We create meaningful brand interactions",
    img: "https://i.imgur.com/SYgLTmi.png",
    url: "https://www.chooseignite.com/",
    location: "Rochester Hills, MI",
  },
  {
    id: 5,
    name: "Fusionary",
    tagline: "Award-winning digital studio based in Michigan.",
    img: "https://i.imgur.com/oBxlxZ7.png",
    url: "https://fusionary.com/",
    location: "Grand Rapids, MI",
  },
  {
    id: 6,
    name: "Trademark Productions",
    tagline: "Keep Your Goals on Track!",
    img: "https://i.imgur.com/o5aHke2.jpg",
    url: "https://www.tmprod.com/",
    location: "Pleasant Ridge, MI",
  },
  {
    id: 7,
    name: "Kaleidico",
    tagline: "Marketing & Design Agency Specializing in Lead Gen",
    img: "https://i.imgur.com/T8r9pJl.jpg",
    url: "https://kaleidico.com/",
    location: "Flat Rock, MI",
  },
  {
    id: 8,
    name: "Kmotion Design Inc.",
    tagline: "We create website experiences that drive results.",
    img: "https://i.imgur.com/QWWZ2Jp.png",
    url: "https://kmotiondesign.com/",
    location: "Grand Rapids, MI",
  },
  {
    id: 10,
    name: "Unified Infotech",
    tagline: "Your End-To-End Web & Mobile Solution Partners",
    img: "https://i.imgur.com/5kD9og3.jpg",
    url: "https://snappywebdesign.net/",
    location: "Adrian, MI",
  },
  {
    id: 11,
    name: "Caveni Digital Solutions",
    tagline: "We Build Brands",
    img: "https://i.imgur.com/JHXi22E.png",
    url: "https://caveni.com/",
    location: "Adrian, MI",
  },
  {
    id: 9,
    name: "Snappy Web Design",
    tagline: "Fortune-500 Websites for Small Businesses",
    img: "https://i.imgur.com/Iv8jKUx.png",
    url: "https://snappywebdesign.net/",
    location: "Ann Arbor, MI",
  },
  {
    id: 12,
    name: "Bachoo",
    tagline: "Architecting the Digital. Apps. Websites. Brands.",
    img: "https://i.imgur.com/qQ7UFrL.png",
    url: "https://bachoodesign.com/",
    location: "Adrian, MI",
  },
  {
    id: 14,
    name: "Sidebench",
    tagline: "LA's Top Mobile App & Software Development Company",
    img: "https://i.imgur.com/LcXbbqL.png",
    url: "http://sidebench.com/",
    location: "Albion, MI",
  },
  {
    id: 15,
    name: "3 SIDED CUBE",
    tagline: "Tech For Good - Global App Development Company",
    img: "https://i.imgur.com/knneYfL.png",
    url: "https://www.3sidedcube.com/",
    location: "Allegan, MI",
  },
  {
    id: 100,
    name: "Snappy Web Design",
  },
  {
    id: 16,
    name: "Louis Websdale",
    tagline:
      "Whether you have video content or a website, I can help get your brand, your business and your message out there.",
    img: "https://i.imgur.com/veRL7AA.png",
    url: "https://www.louiswebsdale.co.uk/",
    location: "Brentwood, Essex",
    rate: "$27/hr",
    stars: 5,
    rating: 4,
    description: `If you’re looking for cost-effective web design services from a website designer that you can rely on, I can help.
    You may have an existing website that needs a refresh, or you might be looking to launch a brand-new site.
    My services include website planning, website design, speed optimisation, SSL certificates, hosting and SEO services.
    For existing sites, I can help with monthly website changes including loading new blogs, and site maintenance with monthly plugin updates.`,
  },
]

const useStyles = makeStyles((theme) => ({
  sectionCtr: {
    paddingBottom: "2em",
  },
  pageTitle: {
    color: `${dark}`,
    fontWeight: 500,
    fontSize: "1.75rem",
    lineHeight: "2.25rem",
    paddingTop: "2em",
    paddingBottom: ".15em",
  },
  subSectionHeader: {
    fontSize: ".8rem",
    color: "#666666",
    fontWeight: 400,
  },
  subSectionBody: {
    fontSize: ".9rem",
    color: "rgba(0,0,0,.7)",
  },
  servicesHeader: {
    fontSize: "1.5rem",
    color: "#666666",
    fontWeight: 500,
    marginBottom: "1em",
  },
  h1: {
    color: dark,
    [theme.breakpoints.down("md")]: {
      fontSize: "2.5rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.5rem",
    },
  },
  h2: {
    fontWeight: 500,
    fontSize: "1.75rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.25rem",
    },
  },
  h3: {
    fontSize: "1.05rem",
    lineHeight: "1.5rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem",
    },
  },
  h4: {},
  h5: {
    fontSize: "1.25rem",
    fontWeight: 500,
  },
  h5alt: {
    fontSize: "1.25rem",
    fontWeight: 400,
  },
  h6: {
    marginRight: theme.spacing(0.25),
    marginTop: theme.spacing(0.25),
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    fontWeight: 400,
    fontSize: "1rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: ".9rem",
    },
  },
  h6alt: {
    marginRight: theme.spacing(0.25),
    marginTop: theme.spacing(0.25),
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    fontWeight: 400,
    fontSize: ".9rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: ".85rem",
    },
  },
  paper: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  websiteBtn: {
    fontSize: ".9rem",
    textDecoration: "capitalize",
    backgroundColor: dark,
    color: "#fff",
    borderRadius: 25,
    transition: ".3s",
    width: "60%",
    "&:hover": {
      backgroundColor: primary,
      color: "#fff",
      "& > a": {
        color: "#fff",
      },
    },
    [theme.breakpoints.down("sm")]: {
      width: "75%",
      fontSize: ".8rem",
    },
    "& span": {
      textTransform: "capitalize",
    },
  },
  websiteBtnAlt: {
    fontSize: ".9rem",
    textDecoration: "capitalize",
    color: dark,
    borderRadius: 25,
    transition: ".3s",
    width: "60%",
    "&:hover": {
      backgroundColor: primary,
      color: dark,
      "& > a": {
        color: dark,
      },
    },
    [theme.breakpoints.down("sm")]: {
      width: "75%",
      fontSize: ".8rem",
    },
    "& span": {
      textTransform: "capitalize",
    },
  },
  aFix: {
    "&:hover > a": {
      color: "#fff",
    },
  },
  companyLogo: {
    maxHeight: 48,
    maxWidth: 48,
  },
  companyLogoAlt: {
    maxHeight: 30,
    maxWidth: 30,
  },
  companyInfoBorder: {
    borderRight: "1px solid rgba(0, 0, 0, 0.12)",
    [theme.breakpoints.down("xs")]: {
      borderRight: "none",
    },
  },
  // menuFix: {
  //   "&:hover > a": {
  //     color: "inherit",
  //   },
  // },
}))

// const StyledBreadcrumb = withStyles((theme) => ({
//   root: {
//     backgroundColor: "transparent",
//     fontSize: ".8rem",
//     height: theme.spacing(3),
//     fontWeight: theme.typography.fontWeightRegular,
//     "&:hover, &:focus": {
//       backgroundColor: "transparent",
//     },
//     "&:active": {
//       boxShadow: theme.shadows[1],
//       backgroundColor: "transparent",
//     },
//   },
// }))(Chip)

export default function CompanyDirectoryIndex({ data }) {
  const theme = useTheme()
  const classes = useStyles()
  // const [open, setOpen] = React.useState(false)
  // const anchorRef = React.useRef(null)
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"))

  let SnappyCompanyCard = () => (
    <Grid
      container
      justify="center"
      style={{ paddingTop: "1em" }}
      className={classes.sectionCtr}
    >
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Grid container>
              <Grid item xs={12} sm={8} className={classes.companyInfoBorder}>
                <Grid container justify="center">
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={1} container alignItems="center">
                        <Avatar
                          variant="rounded"
                          className={classes.companyLogo}
                          src={SnappyLogo}
                          alt={`Snappy Web Designs Logo`}
                        >
                          S
                        </Avatar>
                      </Grid>
                      <Grid item xs={11}>
                        <Grid container direction="column">
                          <Grid item xs={12}>
                            <Typography
                              variant="h5"
                              className={classes.h5}
                              style={{
                                marginLeft: matchesXS
                                  ? theme.spacing(2)
                                  : theme.spacing(1),
                              }}
                            >
                              Snappy Web Design
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography
                              variant="h6"
                              className={classes.h6}
                              style={{
                                marginLeft: matchesXS
                                  ? theme.spacing(2)
                                  : theme.spacing(1),
                              }}
                            >
                              We make web design & development easy for small
                              businesses
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} style={{ paddingTop: ".75rem" }}>
                    <Rating name="read-only" value={5} readOnly />
                    <span
                      style={{
                        fontSize: "1.15rem",
                        color: `${primary}`,
                        fontWeight: 500,
                        verticalAlign: "top",
                        marginLeft: theme.spacing(0.75),
                      }}
                    >
                      5.0 (Verified)
                    </span>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      paddingTop: theme.spacing(1),
                      paddingRight: theme.spacing(1),
                    }}
                  >
                    <Typography variant="body1" paragraph gutterBottom>
                      Snappy Web Design was founded in 2021 to assist small
                      businesses in achieving the same quality of websites as
                      enterprise level corporations. We are experts in bringing
                      small business websites to scale and making them
                      SEO-friendly, responsive, and modern!{" "}
                      <span>
                        <Link to="/">Learn more about Snappy Web Design</Link>
                      </span>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Grid container justify="center">
                  <Hidden smUp>
                    <Grid item xs={12}>
                      <Divider
                        style={{
                          maxWidth: "95%",
                          marginLeft: "auto",
                          marginRight: "auto",
                          marginBottom: "2rem",
                        }}
                      />
                    </Grid>
                  </Hidden>
                  <Grid
                    item
                    xs={12}
                    container
                    justify="center"
                    className={classes.aFix}
                  >
                    <Button
                      variant="contained"
                      size="large"
                      className={classes.websiteBtn}
                      component={Link}
                      to="/"
                    >
                      View Website
                    </Button>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      paddingTop: theme.spacing(3),
                    }}
                  >
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography
                          variant="h6"
                          className={classes.subSectionHeader}
                          gutterBottom
                          align="center"
                        >
                          Hourly Rate
                        </Typography>
                        <Typography
                          variant="h6"
                          className={classes.subSectionBody}
                          gutterBottom
                          paragraph
                          align="center"
                        >
                          $40 - $150/hr
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography
                          variant="h6"
                          className={classes.subSectionHeader}
                          gutterBottom
                          align="center"
                        >
                          Locations
                        </Typography>
                        <Typography
                          variant="h6"
                          className={classes.subSectionBody}
                          gutterBottom
                          paragraph
                          align="center"
                        >
                          Brighton, MI
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
          <Divider
            style={{
              maxWidth: "95%",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          />
          <CardActions>
            <Grid
              item
              xs={12}
              container
              justify={matchesXS ? "center" : undefined}
              direction={matchesXS ? "row" : "row-reverse"}
            >
              <Typography
                variant="subtitle1"
                component={Link}
                to="/contact/"
                style={{
                  color: "rgba(0,0,0,.5)",
                  fontSize: "1rem",
                  paddingRight: theme.spacing(2),
                  textDecoration: "none",
                }}
              >
                <FaPhone style={{ marginRight: theme.spacing(1.25) }} />
                Contact
              </Typography>
            </Grid>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  )

  // const handleToggle = () => {
  //   setOpen((prevOpen) => !prevOpen)
  // }

  // const handleClose = (event) => {
  //   if (anchorRef.current && anchorRef.current.contains(event.target)) {
  //     return
  //   }

  //   setOpen(false)
  // }

  // function handleListKeyDown(event) {
  //   if (event.key === "Tab") {
  //     event.preventDefault()
  //     setOpen(false)
  //   }
  // }

  // // return focus to the button when we transitioned from !open -> open
  // const prevOpen = React.useRef(open)
  // React.useEffect(() => {
  //   if (prevOpen.current === true && open === false) {
  //     anchorRef.current.focus()
  //   }

  //   prevOpen.current = open
  // }, [open])

  return (
    <Layout grey={true}>
      <Seo
        noTitlePrefix={true}
        image="https://snappywebdesign.net/images/directoryimg.jpg"
        title={`Top Rated Web Design Companies 2021 | Snappy Web Design`}
        description={`List of the best web design companies by reviews & ratings. Find the best web designer to develop your small businesses website.`}
      />
      <Background>
        <h1 className={classes.h1}>
          Top Rated
          <br />
          Web Design Companies
        </h1>
      </Background>
      <DirectoryBreadcrumbs />
      {/* <Breadcrumbs
        aria-label="breadcrumb"
        style={{ marginTop: ".75em", marginLeft: "1em", fontSize: ".8rem" }}
      >
        <Link color="inherit" component={Link} to="/">
          Home
        </Link>
        <StyledBreadcrumb
          label="Top Web Design Companies"
          deleteIcon={<ExpandMoreIcon />}
          onDelete={handleToggle}
          ref={anchorRef}
          aria-controls={open ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        />
      </Breadcrumbs>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                  className={classes.menuFix}
                >
                  <MenuItem
                    component={Link}
                    style={{ fontSize: ".9rem" }}
                    to="/directory/michigan/top-web-design-companies/"
                  >
                    Michigan Companies
                  </MenuItem>
                  <MenuItem
                    component={Link}
                    style={{ fontSize: ".9rem" }}
                    to="/directory/ann-arbor/top-web-design-companies/"
                  >
                    Ann Arbor Companies
                  </MenuItem>
                  <MenuItem
                    component={Link}
                    style={{ fontSize: ".9rem" }}
                    to="/directory/grand-rapids/top-web-design-companies/"
                  >
                    Grand Rapids Companies
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper> */}

      <Grid
        container
        justify="center"
        style={{
          paddingTop: "0.5rem",
          maxWidth: matchesXS ? undefined : 960,
          paddingLeft: matchesXS ? undefined : "1rem",
          paddingRight: matchesXS ? undefined : "1rem",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography
              variant="h2"
              className={classes.h2}
              paragraph
              gutterBottom
              align="center"
            >
              Best Web Design Companies
            </Typography>
            <Container maxWidth="md">
              <Typography
                variant="h3"
                className={classes.h3}
                paragraph
                gutterBottom
                style={{ padding: "0 1rem" }}
              >
                In search of the best web design company? Search our directory
                of the top companies that provide web designs services. Need a
                React.js website developer? Wordpress business website?
                Regardless of your businesses needs, these web development firms
                leverage a wide range of technologies. Wondering how much it
                costs to design a website? Use our{" "}
                <Link to="/pricing/">website pricing tool</Link>!
              </Typography>
            </Container>

            <Divider />
            <Typography
              variant="h4"
              className={classes.h3}
              style={{ paddingTop: "1rem" }}
              paragraph
              gutterBottom
              align="center"
            >
              <span style={{ fontWeight: 600 }}>10+</span> Companies
            </Typography>
          </Paper>
        </Grid>
      </Grid>

      <Container
        style={{
          maxWidth: 960,
          backgroundColor: "#f5f5f5",
          padding: matchesXS ? 0 : undefined,
        }}
      >
        <Grid
          container
          justify="center"
          style={{ paddingTop: "1em" }}
          className={classes.sectionCtr}
        >
          <Grid item xs={12}>
            <Typography
              variant="h5"
              style={{
                fontSize: "1.25rem",
                fontWeight: 500,
                paddingTop: "1.5rem",
              }}
              align={matchesXS ? "center" : undefined}
              gutterBottom
            >
              List of the Top Web Design Companies - Best Web Designers
            </Typography>
          </Grid>

          {/* <SnappyCompanyCard /> */}

          {WebCompanies.reverse().map((company) => {
            if (company.name === "Snappy Web Design") {
              return <SnappyCompanyCard key={`${company.id}snappy`} />
            }
            return (
              <Grid
                container
                justify="center"
                className={classes.sectionCtr}
                key={company.id}
              >
                <Grid item xs={12}>
                  <Card>
                    <CardContent>
                      <Grid container>
                        <Grid
                          item
                          xs={12}
                          sm={8}
                          className={classes.companyInfoBorder}
                        >
                          <Grid container justify="center">
                            <Grid item xs={12}>
                              <Grid container>
                                <Grid item xs={1} container alignItems="center">
                                  <Avatar
                                    variant="rounded"
                                    className={classes.companyLogoAlt}
                                    src={company.img}
                                    alt={`${company.name}'s logo`}
                                  >
                                    C
                                  </Avatar>
                                </Grid>
                                <Grid item xs={11}>
                                  <Grid container direction="column">
                                    <Grid item xs={12}>
                                      <Typography
                                        variant="h5"
                                        className={classes.h5alt}
                                        style={{
                                          marginLeft: matchesXS
                                            ? theme.spacing(2)
                                            : theme.spacing(1),
                                        }}
                                      >
                                        {company.name}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                      <Typography
                                        variant="h6"
                                        className={classes.h6alt}
                                        style={{
                                          marginLeft: matchesXS
                                            ? theme.spacing(2)
                                            : theme.spacing(1),
                                        }}
                                      >
                                        {company.tagline}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12} style={{ paddingTop: ".75rem" }}>
                              <Rating
                                name="pristine"
                                value={company.stars ? company.stars : null}
                                readOnly
                              />
                              <span
                                style={{
                                  fontSize: "1.15rem",
                                  color: "rgba(0,0,0,.5)",
                                  fontWeight: 400,
                                  verticalAlign: "top",
                                  marginLeft: theme.spacing(0.75),
                                }}
                              >
                                {company.rating ? company.rating : "(?)"}
                              </span>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              style={{
                                paddingTop: theme.spacing(1),
                                paddingRight: theme.spacing(1),
                              }}
                            >
                              <Typography
                                variant="body1"
                                paragraph
                                gutterBottom
                              >
                                {company.description
                                  ? truncate(company.description, {
                                      length: 250,
                                      separator: " ",
                                    })
                                  : `Description of ${company.name} pending...`}
                                <br />
                                <span>
                                  <a
                                    href={company.url}
                                    target="_blank"
                                    // referrer="noreferrer"
                                    rel="nofollow noreferrer"
                                  >
                                    Learn more about {company.name}
                                  </a>
                                </span>
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <Grid container justify="center">
                            <Hidden smUp>
                              <Grid item xs={12}>
                                <Divider
                                  style={{
                                    maxWidth: "95%",
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    marginBottom: "2rem",
                                  }}
                                />
                              </Grid>
                            </Hidden>
                            <Grid
                              item
                              xs={12}
                              container
                              justify="center"
                              className={classes.aFix}
                            >
                              <Button
                                variant="outlined"
                                size="large"
                                className={classes.websiteBtnAlt}
                                component="a"
                                href={company.url}
                                target="_blank"
                                // referrer="noreferrer"
                                rel="nofollow"
                              >
                                View Website
                              </Button>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              style={{
                                paddingTop: theme.spacing(3),
                              }}
                            >
                              <Grid container>
                                <Grid item xs={12}>
                                  <Typography
                                    variant="h6"
                                    className={classes.subSectionHeader}
                                    gutterBottom
                                    align="center"
                                  >
                                    Hourly Rate
                                  </Typography>
                                  <Typography
                                    variant="h6"
                                    className={classes.subSectionBody}
                                    gutterBottom
                                    paragraph
                                    align="center"
                                  >
                                    {company.rate ? company.rate : "Unknown"}
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid container>
                                <Grid item xs={12}>
                                  <Typography
                                    variant="h6"
                                    className={classes.subSectionHeader}
                                    gutterBottom
                                    align="center"
                                  >
                                    Locations
                                  </Typography>
                                  <Typography
                                    variant="h6"
                                    className={classes.subSectionBody}
                                    gutterBottom
                                    paragraph
                                    align="center"
                                  >
                                    {company.location}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                    <Divider
                      style={{
                        maxWidth: "95%",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    />
                    <CardActions>
                      <Grid
                        item
                        xs={12}
                        container
                        justify={matchesXS ? "center" : undefined}
                        direction={matchesXS ? "row" : "row-reverse"}
                      >
                        <Typography
                          variant="subtitle1"
                          component={Link}
                          to="/contact/"
                          style={{
                            color: "rgba(0,0,0,.5)",
                            fontSize: "1rem",
                            paddingRight: theme.spacing(2),
                            textDecoration: "none",
                          }}
                        >
                          <FaPhone
                            style={{ marginRight: theme.spacing(1.25) }}
                          />
                          Update Business Information
                        </Typography>
                      </Grid>
                    </CardActions>
                  </Card>
                </Grid>
              </Grid>
            )
          })}
        </Grid>
      </Container>
      <PortfolioCTA small={true} />
    </Layout>
  )
}
